import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="home-wrapper">
      <div className="wrapper">
        <div className="flexed">
          <div>
            <img
              src="/images/davidsapp-logo.png"
              className="logo"
              alt="davidsapp logo"
            />
          </div>
          <h3>404: Not Found</h3>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
